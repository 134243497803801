import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import marloe1 from "../images/commercial/marloe/marloe-1.jpg";
import marloe2 from "../images/commercial/marloe/marloe-2.jpg";
import marloe3 from "../images/commercial/marloe/marloe-3.jpg";
import marloe4 from "../images/commercial/marloe/marloe-4.jpg";
import marloe5 from "../images/commercial/marloe/marloe-5.jpg";
import marloe6 from "../images/commercial/marloe/marloe-6.jpg";
import marloe7 from "../images/commercial/marloe/marloe-7.jpg";
import marloe8 from "../images/commercial/marloe/marloe-8.jpg";
import marloe9 from "../images/commercial/marloe/marloe-9.jpg";
import marloe10 from "../images/commercial/marloe/marloe-10.jpg";
import marloe11 from "../images/commercial/marloe/marloe-11.jpg";
import marloe13 from "../images/commercial/marloe/marloe-13.jpg";
import marloe14 from "../images/commercial/marloe/marloe-14.jpg";
import marloe15 from "../images/commercial/marloe/marloe-15.jpg";
import marloe16 from "../images/commercial/marloe/marloe-16.jpg";
import marloe17 from "../images/commercial/marloe/marloe-17.jpg";
import marloe18 from "../images/commercial/marloe/marloe-18.jpg";
import marloe19 from "../images/commercial/marloe/marloe-19.jpg";
import marloe20 from "../images/commercial/marloe/marloe-20.jpg";
import marloe21 from "../images/commercial/marloe/marloe-21.jpg";
import marloe22 from "../images/commercial/marloe/marloe-22.jpg";
import marloe23 from "../images/commercial/marloe/marloe-23.jpg";
import marloe24 from "../images/commercial/marloe/marloe-24.jpg";

const photos = [
  {
    src: marloe1,
    width: 2,
    height: 3,
  },
  {
    src: marloe22,
    width: 4,
    height: 5,
  },
  {
    src: marloe2,
    width: 2,
    height: 3,
  },
  {
    src: marloe6,
    width: 2,
    height: 3,
  },
  {
    src: marloe21,
    width: 2,
    height: 3,
  },
  {
    src: marloe3,
    width: 2,
    height: 3,
  },
  {
    src: marloe4,
    width: 4,
    height: 5,
  },
  {
    src: marloe9,
    width: 2,
    height: 3,
  },
  {
    src: marloe20,
    width: 4,
    height: 5,
  },
  {
    src: marloe5,
    width: 4,
    height: 5,
  },
  {
    src: marloe7,
    width: 2,
    height: 3,
  },
  {
    src: marloe18,
    width: 2,
    height: 3,
  },
  {
    src: marloe8,
    width: 2,
    height: 3,
  },
  {
    src: marloe10,
    width: 2,
    height: 3,
  },
  {
    src: marloe11,
    width: 2,
    height: 3,
  },
  {
    src: marloe13,
    width: 2,
    height: 3,
  },
  {
    src: marloe14,
    width: 4,
    height: 5,
  },
  {
    src: marloe15,
    width: 2,
    height: 3,
  },
  {
    src: marloe16,
    width: 2,
    height: 3,
  },
  {
    src: marloe17,
    width: 2,
    height: 3,
  },
  {
    src: marloe19,
    width: 2,
    height: 3,
  },
  {
    src: marloe23,
    width: 2,
    height: 3,
  },
  {
    src: marloe24,
    width: 4,
    height: 5,
  }
];

export default function Marloe() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Marloe">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Marloe</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Marloe Watch"
        img={marloe7}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}