import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import madeira2 from "../images/commercial/madeira/madeira-2.jpg";
import madeira3 from "../images/commercial/madeira/madeira-3.jpg";
import madeira4 from "../images/commercial/madeira/madeira-4.jpg";
import madeira5 from "../images/commercial/madeira/madeira-5.jpg";
import madeira6 from "../images/commercial/madeira/madeira-6.jpg";
import madeira7 from "../images/commercial/madeira/madeira-7.jpg";
import madeira8 from "../images/commercial/madeira/madeira-8.jpg";
import madeira11 from "../images/commercial/madeira/madeira-11.jpg";
import madeira14 from "../images/commercial/madeira/madeira-14.jpg";
import madeira16 from "../images/commercial/madeira/madeira-16.jpg";
import madeira17 from "../images/commercial/madeira/madeira-17.jpg";
import madeira18 from "../images/commercial/madeira/madeira-18.jpg";
import madeira19 from "../images/commercial/madeira/madeira-19.jpg";
import madeira20 from "../images/commercial/madeira/madeira-20.jpg";
import madeira21 from "../images/commercial/madeira/madeira-21.jpg";
import madeira22 from "../images/commercial/madeira/madeira-22.jpg";
import madeira24 from "../images/commercial/madeira/madeira-24.jpg";
import madeira25 from "../images/commercial/madeira/madeira-25.jpg";
import madeira26 from "../images/commercial/madeira/madeira-26.jpg";
import madeira27 from "../images/commercial/madeira/madeira-27.jpg";

const photos = [
  {
    src: madeira20,
    width: 4,
    height: 5,
  },
  {
    src: madeira2,
    width: 4,
    height: 5
  },
  {
    src: madeira22,
    width: 4,
    height: 5,
  },
  {
    src: madeira25,
    width: 4,
    height: 5,
  },
  {
    src: madeira26,
    width: 2,
    height: 3,
  },
  {
    src: madeira3,
    width: 3,
    height: 4
  },
  {
    src: madeira21,
    width: 4,
    height: 5,
  },
  {
    src: madeira7,
    width: 3,
    height: 4
  },
  {
    src: madeira17,
    width: 4,
    height: 5,
  },
  {
    src: madeira19,
    width: 3,
    height: 4,
  },
  {
    src: madeira4,
    width: 4,
    height: 3
  },
  {
    src: madeira5,
    width: 4,
    height: 3
  },
  {
    src: madeira6,
    width: 4,
    height: 3
  },
  {
    src: madeira8,
    width: 4,
    height: 5
  },
  {
    src: madeira11,
    width: 4,
    height: 5
  },
  {
    src: madeira14,
    width: 4,
    height: 5,
  },
  {
    src: madeira16,
    width: 4,
    height: 5,
  },
  {
    src: madeira18,
    width: 4,
    height: 5,
  },
  {
    src: madeira24,
    width: 4,
    height: 5,
  },
  {
    src: madeira27,
    width: 4,
    height: 5,
  }
];

export default function Madeira() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Madeira">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Visit Madeira</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Visit Madeira"
        img={madeira19}
      ></PageTitle>
      <div className="Page-content">
        <div className="video-container">
          <iframe className="horizontal" width="1000" height="562" src="https://www.youtube.com/embed/fX7ZVgEfjKg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}