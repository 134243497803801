import React from "react";
import "./Footer.scss";
import facebook from "../images/socials/facebook.svg";
import instagram from "../images/socials/instagram.svg";
import mail from "../images/socials/mail.svg";
import twitter from "../images/socials/twitter.svg";
import youtube from "../images/socials/youtube.svg";

const socials = [
  {
    img: instagram,
    href: "http://instagram.com/joaoffalcao",
    alt: "instagram",
  },
  {
    img: facebook,
    href: "https://facebook.com/joaofalcaophoto",
    alt: "facebook",
  },
  {
    img: twitter,
    href: "https://twitter.com/joaoffalcao",
    alt: "twitter",
  },
  {
    img: youtube,
    href: "https://www.youtube.com/user/joaoffalcaoa",
    alt: "youtube",
  },
  {
    img: mail,
    href: "mailto:hello@joaofalcao.pt",
    alt: "mail",
  }
];

export default function Footer() {
  return (
    <footer className="Footer">
      <div>© 2021, João Falcão</div>
      <div className="Socials">
        {socials.map((item) => (
          <a className="Socials-link" href={item.href} target="_blank">
            <img src={item.img} alt={item.alt}></img>
          </a>
        ))}
      </div>
    </footer>
  );
}