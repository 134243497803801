import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import vincero1 from "../images/commercial/vincero/vincero-1.jpg";
import vincero2 from "../images/commercial/vincero/vincero-2.jpg";
import vincero4 from "../images/commercial/vincero/vincero-4.jpg";
import vincero5 from "../images/commercial/vincero/vincero-5.jpg";
import vincero6 from "../images/commercial/vincero/vincero-6.jpg";
import vincero7 from "../images/commercial/vincero/vincero-7.jpg";

const photos = [
  { src: vincero5 },
  { src: vincero1 },
  { src: vincero6 },
  { src: vincero2 },
  { src: vincero4 },
  { src: vincero7 }
].map(ph => (
  {
    ...ph,
    width: 4,
    height: 5
}));

export default function Vincero() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Vincero">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Vincero Watch</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Vincero Watch"
        img={vincero4}
      ></PageTitle>
      <div className="Page-content">
        <div className="video-container">
          <iframe width="300" height="533" src="https://www.youtube.com/embed/8ZYfBX-vIZM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}