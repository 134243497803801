import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";
import { useTranslation } from 'react-i18next';

import weddings1 from "../images/weddings/weddings-1.jpg";
import weddings2 from "../images/weddings/weddings-2.jpg";
import weddings3 from "../images/weddings/weddings-3.jpg";
import weddings4 from "../images/weddings/weddings-4.jpg";
import weddings5 from "../images/weddings/weddings-5.jpg";
import weddings6 from "../images/weddings/weddings-6.jpg";
import weddings7 from "../images/weddings/weddings-7.jpg";
import weddings8 from "../images/weddings/weddings-8.jpg";
import weddings9 from "../images/weddings/weddings-9.jpg";
import weddings10 from "../images/weddings/weddings-10.jpg";
import weddings11 from "../images/weddings/weddings-11.jpg";
import weddings12 from "../images/weddings/weddings-12.jpg";
import weddings13 from "../images/weddings/weddings-13.jpg";
import weddings14 from "../images/weddings/weddings-14.jpg";
import weddings15 from "../images/weddings/weddings-15.jpg";
import weddings16 from "../images/weddings/weddings-16.jpg";
import weddings17 from "../images/weddings/weddings-17.jpg";
import weddings18 from "../images/weddings/weddings-18.jpg";
import weddings19 from "../images/weddings/weddings-19.jpg";
import weddings20 from "../images/weddings/weddings-20.jpg";
import weddings21 from "../images/weddings/weddings-21.jpg";
import weddings22 from "../images/weddings/weddings-22.jpg";
import weddings23 from "../images/weddings/weddings-23.jpg";
import weddings24 from "../images/weddings/weddings-24.jpg";
import weddings25 from "../images/weddings/weddings-25.jpg";
import weddings26 from "../images/weddings/weddings-26.jpg";
import weddings27 from "../images/weddings/weddings-27.jpg";
import weddings28 from "../images/weddings/weddings-28.jpg";
import weddings29 from "../images/weddings/weddings-29.jpg";
import weddings30 from "../images/weddings/weddings-30.jpg";
import weddings31 from "../images/weddings/weddings-31.jpg";
import weddings32 from "../images/weddings/weddings-32.jpg";
import weddings33 from "../images/weddings/weddings-33.jpg";
import weddings34 from "../images/weddings/weddings-34.jpg";
import weddings35 from "../images/weddings/weddings-35.jpg";
import weddings36 from "../images/weddings/weddings-36.jpg";
import weddings37 from "../images/weddings/weddings-37.jpg";
import weddings38 from "../images/weddings/weddings-38.jpg";
import weddings39 from "../images/weddings/weddings-39.jpg";
import weddings40 from "../images/weddings/weddings-40.jpg";
import weddings41 from "../images/weddings/weddings-41.jpg";
import weddings42 from "../images/weddings/weddings-42.jpg";
import weddings43 from "../images/weddings/weddings-43.jpg";
import weddings44 from "../images/weddings/weddings-44.jpg";
import weddings45 from "../images/weddings/weddings-45.jpg";
import weddings46 from "../images/weddings/weddings-46.jpg";

const photos = [
  {
    src: weddings1,
    width: 3,
    height: 2,
  },
  {
    src: weddings2,
    width: 2,
    height: 3,
  },
  {
    src: weddings3,
    width: 2,
    height: 3
  },
  {
    src: weddings4,
    width: 2,
    height: 3
  },
  {
    src: weddings5,
    width: 3,
    height: 2
  },
  {
    src: weddings6,
    width: 3,
    height: 2
  },
  {
    src: weddings7,
    width: 3,
    height: 2
  },
  {
    src: weddings8,
    width: 3,
    height: 2
  },
  {
    src: weddings9,
    width: 3,
    height: 2
  },
  {
    src: weddings10,
    width: 3,
    height: 2
  },
  {
    src: weddings11,
    width: 3,
    height: 2
  },
  {
    src: weddings12,
    width: 2,
    height: 3
  },
  {
    src: weddings13,
    width: 2,
    height: 3
  },
  {
    src: weddings14,
    width: 2,
    height: 3
  },
  {
    src: weddings15,
    width: 3,
    height: 2
  },
  {
    src: weddings16,
    width: 3,
    height: 2
  },
  {
    src: weddings17,
    width: 3,
    height: 2
  },
  {
    src: weddings18,
    width: 2,
    height: 3
  },
  {
    src: weddings19,
    width: 2,
    height: 3
  },
  {
    src: weddings20,
    width: 3,
    height: 2
  },
  {
    src: weddings21,
    width: 3,
    height: 2
  },
  {
    src: weddings22,
    width: 2,
    height: 3
  },
  {
    src: weddings23,
    width: 2,
    height: 3
  },
  {
    src: weddings24,
    width: 3,
    height: 2
  },
  {
    src: weddings25,
    width: 3,
    height: 2
  },
  {
    src: weddings26,
    width: 3,
    height: 2
  },
  {
    src: weddings27,
    width: 2,
    height: 3
  },
  {
    src: weddings28,
    width: 2,
    height: 3
  },
  {
    src: weddings29,
    width: 2,
    height: 3
  },
  {
    src: weddings30,
    width: 2,
    height: 3
  },
  {
    src: weddings31,
    width: 2,
    height: 3
  },
  {
    src: weddings32,
    width: 2,
    height: 3
  },
  {
    src: weddings33,
    width: 2,
    height: 3
  },
  {
    src: weddings34,
    width: 2,
    height: 3
  },
  {
    src: weddings35,
    width: 3,
    height: 2
  },
  {
    src: weddings36,
    width: 3,
    height: 2
  },
  {
    src: weddings37,
    width: 3,
    height: 2
  },
  {
    src: weddings38,
    width: 3,
    height: 2
  },
  {
    src: weddings39,
    width: 2,
    height: 3
  },
  {
    src: weddings40,
    width: 3,
    height: 2
  },
  {
    src: weddings41,
    width: 3,
    height: 2
  },
  {
    src: weddings42,
    width: 3,
    height: 2
  },
  {
    src: weddings43,
    width: 3,
    height: 2
  },
  {
    src: weddings44,
    width: 3,
    height: 2
  },
  {
    src: weddings45,
    width: 3,
    height: 2
  },
  {
    src: weddings46,
    width: 2,
    height: 3
  },
];

export default function Weddings() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Weddings">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Weddings</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label={t('Weddings')}
        img={weddings5}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}