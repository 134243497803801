import React from "react";
import MetaTags from 'react-meta-tags';
import MenuLinks from "../components/MenuLinks";
import { useTranslation } from 'react-i18next';

import automotive from "../images/commercial/audi/audi-9.jpg";
import commercial from "../images/commercial/marloe/marloe-19.jpg";
import fashion from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-14.jpg";
import film from "../images/film/film-5.jpg";
import interiors from "../images/interiors/interiors-9.jpg";
import outdoor from "../images/outdoor/outdoor-4.jpg";
import weddings from "../images/weddings/weddings-45.jpg";


export default function Portfolio() {
  const { t, i18n } = useTranslation();
  const links = [
    {
      label: t('Fashion'),
      link: "/fashion",
      img: fashion,
    },
    {
      label: t('Commercial'),
      link: "/commercial",
      img: commercial,
    },
    {
      label: t('Automotive'),
      link: "/automotive",
      img: automotive,
    },
    {
      label: t('Weddings'),
      link: "/weddings",
      img: weddings,
    },
    {
      label: t('Interiors'),
      link: "/interiors",
      img: interiors,
    },
    {
      label: t('Outdoor'),
      link: "/outdoor",
      img: outdoor,
    },
    {
      label: t('Film'),
      link: "/film",
      img: film,
    },
  ];

  return (
    <div className="Portfolio">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Portfolio</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor and Portfolio photographer specialized in documentary and lifestyle photography || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Comercial e Casamentos do Porto especializado em fotografia documental e do dia a dia." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Portfolio, Comercial, Natureza, Retratos, Marcas" />
      </MetaTags>
      <div className="Page-title">Portfolio</div>
      <MenuLinks links={links}></MenuLinks>
    </div>
  );
}