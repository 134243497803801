import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import film1 from "../images/film/film-1.jpg";
import film3 from "../images/film/film-3.jpg";
import film4 from "../images/film/film-4.jpg";
import film5 from "../images/film/film-5.jpg";
import film6 from "../images/film/film-6.jpg";
import film7 from "../images/film/film-7.jpg";
import film8 from "../images/film/film-8.jpg";
import film9 from "../images/film/film-9.jpg";
import film10 from "../images/film/film-10.jpg";
import film11 from "../images/film/film-11.jpg";
import film12 from "../images/film/film-12.jpg";
import film13 from "../images/film/film-13.jpg";
import film14 from "../images/film/film-14.jpg";
import film15 from "../images/film/film-15.jpg";
import film17 from "../images/film/film-17.jpg";
import film18 from "../images/film/film-18.jpg";
import film19 from "../images/film/film-19.jpg";
import film20 from "../images/film/film-20.jpg";
import film21 from "../images/film/film-21.jpg";
import film22 from "../images/film/film-22.jpg";
import film23 from "../images/film/film-23.jpg";
import film24 from "../images/film/film-24.jpg";
import film25 from "../images/film/film-25.jpg";
import film26 from "../images/film/film-26.jpg";
import film27 from "../images/film/film-27.jpg";
import film28 from "../images/film/film-28.jpg";
import film29 from "../images/film/film-29.jpg";
import film30 from "../images/film/film-30.jpg";
import film31 from "../images/film/film-31.jpg";
import film32 from "../images/film/film-32.jpg";

const photos = [
  {
    src: film20,
    width: 2,
    height: 3,
  },
  {
    src: film22,
    width: 2,
    height: 3,
  },
  {
    src: film27,
    width: 2,
    height: 3,
  },
  {
    src: film1,
    width: 2,
    height: 3,
  },
  {
    src: film31,
    width: 3,
    height: 2,
  },
  {
    src: film3,
    width: 3,
    height: 2,
  },
  {
    src: film4,
    width: 2,
    height: 3,
  },
  {
    src: film5,
    width: 2,
    height: 3,
  },
  {
    src: film6,
    width: 2,
    height: 3,
  },
  {
    src: film7,
    width: 2,
    height: 3,
  },
  {
    src: film8,
    width: 2,
    height: 3,
  },
  {
    src: film10,
    width: 2,
    height: 3,
  },
  {
    src: film12,
    width: 2,
    height: 3,
  },
  {
    src: film29,
    width: 2,
    height: 3,
  },
  {
    src: film13,
    width: 2,
    height: 3,
  },
  {
    src: film14,
    width: 2,
    height: 3,
  },
  {
    src: film15,
    width: 2,
    height: 3,
  },
  {
    src: film17,
    width: 2,
    height: 3,
  },
  {
    src: film18,
    width: 2,
    height: 3,
  },
  {
    src: film19,
    width: 2,
    height: 3,
  },
  {
    src: film21,
    width: 2,
    height: 3,
  },
  {
    src: film23,
    width: 2,
    height: 3,
  },
  {
    src: film24,
    width: 2,
    height: 3,
  },
  {
    src: film26,
    width: 2,
    height: 3,
  },
  {
    src: film28,
    width: 2,
    height: 3,
  },
  {
    src: film30,
    width: 3,
    height: 2,
  },
  {
    src: film9,
    width: 3,
    height: 2,
  },
  {
    src: film11,
    width: 2,
    height: 3,
  },
  {
    src: film25,
    width: 2,
    height: 3,
  },
  {
    src: film32,
    width: 2,
    height: 3,
  }
];

export default function Film() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Film">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Film</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor and Commercial photographer specialized in documentary and film photography || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Comercial e Casamentos do Porto especializado em fotografia documental e do dia a dia." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Film"
        img={film5}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}