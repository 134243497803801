import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import outdoor1 from "../images/outdoor/outdoor-1.jpg";
import outdoor2 from "../images/outdoor/outdoor-2.jpg";
import outdoor3 from "../images/outdoor/outdoor-3.jpg";
import outdoor4 from "../images/outdoor/outdoor-4.jpg";
import outdoor5 from "../images/outdoor/outdoor-5.jpg";
import outdoor6 from "../images/outdoor/outdoor-6.jpg";
import outdoor7 from "../images/outdoor/outdoor-7.jpg";
import outdoor8 from "../images/outdoor/outdoor-8.jpg";
import outdoor9 from "../images/outdoor/outdoor-9.jpg";
import outdoor10 from "../images/outdoor/outdoor-10.jpg";
import outdoor11 from "../images/outdoor/outdoor-11.jpg";
import outdoor12 from "../images/outdoor/outdoor-12.jpg";
import outdoor13 from "../images/outdoor/outdoor-13.jpg";
import outdoor14 from "../images/outdoor/outdoor-14.jpg";
import outdoor15 from "../images/outdoor/outdoor-15.jpg";
import outdoor16 from "../images/outdoor/outdoor-16.jpg";
import outdoor17 from "../images/outdoor/outdoor-17.jpg";
import outdoor18 from "../images/outdoor/outdoor-18.jpg";
import outdoor19 from "../images/outdoor/outdoor-19.jpg";
import outdoor20 from "../images/outdoor/outdoor-20.jpg";
import outdoor21 from "../images/outdoor/outdoor-21.jpg";
import outdoor22 from "../images/outdoor/outdoor-22.jpg";
import outdoor23 from "../images/outdoor/outdoor-23.jpg";
import outdoor24 from "../images/outdoor/outdoor-24.jpg";
import outdoor25 from "../images/outdoor/outdoor-25.jpg";
import outdoor26 from "../images/outdoor/outdoor-26.jpg";
import outdoor27 from "../images/outdoor/outdoor-27.jpg";
import outdoor28 from "../images/outdoor/outdoor-28.jpg";
import outdoor29 from "../images/outdoor/outdoor-29.jpg";

const photos = [
  {
    src: outdoor1,
  },
  {
    src: outdoor2,
  },
  {
    src: outdoor3,
  },
  {
    src: outdoor4,
  },
  {
    src: outdoor5,
  },
  {
    src: outdoor6,
  },
  {
    src: outdoor7,
  },
  {
    src: outdoor8,
  },
  {
    src: outdoor9,
  },
  {
    src: outdoor10,
  },
  {
    src: outdoor11,
  },
  {
    src: outdoor12,
  },
  {
    src: outdoor13,
  },
  {
    src: outdoor14,
  },
  {
    src: outdoor15,
  },
  {
    src: outdoor16,
  },
  {
    src: outdoor17,
  },
  {
    src: outdoor18,
  },
  {
    src: outdoor19,
  },
  {
    src: outdoor20,
  },
  {
    src: outdoor21,
  },
  {
    src: outdoor22,
  },
  {
    src: outdoor23,
  },
  {
    src: outdoor24,
  },
  {
    src: outdoor25,
  },
  {
    src: outdoor26,
  },
  {
    src: outdoor27,
  },
  {
    src: outdoor28,
  },
  {
    src: outdoor29,
  },
].map((photo) => ({
  ...photo,
  width: 4,
  height: 5,
  sizes: ["(min-width: 600px) 90vw,(min-width: 1024px) 33.3vw,100vw"],
}));

export default function Outdoor() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Outdoor">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Outdoor</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Outdoor"
        img={outdoor4}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}