import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import sofiacosta1 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-1.jpg";
import sofiacosta2 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-2.jpg";
import sofiacosta3 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-3.jpg";
import sofiacosta4 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-4.jpg";
import sofiacosta5 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-5.jpg";
import sofiacosta6 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-6.jpg";
import sofiacosta7 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-7.jpg";
import sofiacosta8 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-8.jpg";
import sofiacosta9 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-9.jpg";
import sofiacosta10 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-10.jpg";
import sofiacosta11 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-11.jpg";
import sofiacosta12 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-12.jpg";
import sofiacosta13 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-13.jpg";
import sofiacosta14 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-14.jpg";

const photos = [
  { src: sofiacosta1 },
  { src: sofiacosta2 },
  { src: sofiacosta7 },
  { src: sofiacosta3 },
  { src: sofiacosta4 },
  { src: sofiacosta5 },
  { src: sofiacosta6 },
  { src: sofiacosta8 },
  { src: sofiacosta9 },
  { src: sofiacosta10 },
  { src: sofiacosta11 },
  { src: sofiacosta12 },
  { src: sofiacosta13 },
  { src: sofiacosta14 }
].map((ph) => ({
  ...ph,
  width: 4,
  height: 5
}));

export default function SofiaCostaSS22() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="SofiaCosta">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | SofiaCosta</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="SofiaCosta SS22"
        img={sofiacosta1}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}