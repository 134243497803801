import React from "react";
import MetaTags from 'react-meta-tags';
import MenuLinks from "../components/MenuLinks";
import { useTranslation } from 'react-i18next';

import madeira from "../images/commercial/madeira/madeira-6.jpg";
import amv from "../images/commercial/amv/amv-2.jpg";
import azores from "../images/commercial/azores/azores-29.jpg";
import audi from "../images/commercial/audi/audi-3.jpg";
import autatlantis from "../images/commercial/autatlantis/autatlantis-7.jpg";
import balkan from "../images/commercial/balkan/balkan-3.jpg";
import amaltica from "../images/commercial/amaltica/amaltica-9.jpg";
import bodum from "../images/commercial/bodum/bodum-7.jpg";
import experienceoc from "../images/commercial/experienceoc/experienceoc-17.jpg";
import marloe from "../images/commercial/marloe/marloe-19.jpg";
import transparent from "../images/commercial/transparent/transparent-5.jpg";
import timberland from "../images/commercial/timberland/timberland-7.jpg";
import urbanista from "../images/commercial/urbanista/urbanista-11.jpg";
import vincero from "../images/commercial/vincero/vincero-5.jpg";

const links = [
  {
    label: "AmericanVintage",
    link: "/american-vintage",
    img: amv,
  },
  {
    label: "Transparent",
    link: "/transparent",
    img: transparent,
  },
  {
    label: "Urbanista",
    link: "/urbanista",
    img: urbanista,
  },
  {
    label: "Marloe",
    link: "/marloe",
    img: marloe,
  },
  {
    label: "Bodum",
    link: "/bodum",
    img: bodum,
  },
  {
    label: "Vincero Watches",
    link: "/vincero",
    img: vincero,
  },
  {
    label: "Visit Madeira",
    link: "/visit-madeira",
    img: madeira,
  },
  {
    label: "Audi",
    link: "/audi",
    img: audi,
  },
  {
    label: "Visit Azores",
    link: "/visit-azores",
    img: azores,
  },
  {
    label: "Autatlantis",
    link: "/autatlantis",
    img: autatlantis,
  },
  {
    label: "Amaltica",
    link: "/amaltica",
    img: amaltica,
  },
  {
    label: "Experience OC",
    link: "/experience-oc",
    img: experienceoc,
  },
  {
    label: "Timberland Relógios",
    link: "/timberland",
    img: timberland,
  },
  {
    label: "Balkan Campers",
    link: "/balkan-campers",
    img: balkan,
  },
];

export default function Commercial() {
  const { t, i18n } = useTranslation();

  return (
    <div className="Commercial">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Commercial</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <div className="Page-title">{t('Commercial')}</div>
      <MenuLinks links={links}></MenuLinks>
    </div>
  );
}