import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import automotive1 from "../images/commercial/audi/audi-3.jpg";
import automotive2 from "../images/commercial/audi/audi-4.jpg";
import automotive3 from "../images/commercial/audi/audi-6.jpg";
import automotive4 from "../images/commercial/audi/audi-7.jpg";
import automotive5 from "../images/commercial/audi/audi-9.jpg";
import automotive6 from "../images/commercial/audi/audi-10.jpg";
import automotive8 from "../images/commercial/autatlantis/autatlantis-7.jpg";
import automotive9 from "../images/commercial/autatlantis/autatlantis-8.jpg";
import automotive10 from "../images/commercial/balkan/balkan-4.jpg";
import automotive11 from "../images/commercial/balkan/balkan-5.jpg";
import automotive12 from "../images/commercial/balkan/balkan-10.jpg";
import automotive13 from "../images/commercial/balkan/balkan-11.jpg";
import automotive14 from "../images/commercial/balkan/balkan-14.jpg";
import automotive15 from "../images/commercial/bmw/bmw-2.jpg";
import automotive16 from "../images/commercial/bmw/bmw-4.jpg";
import automotive17 from "../images/commercial/bmw/bmw-5.jpg";
import automotive18 from "../images/commercial/bmw/bmw-6.jpg";
import automotive19 from "../images/commercial/bmw/bmw-7.jpg";
import automotive20 from "../images/commercial/bmw/bmw-8.jpg";
import automotive21 from "../images/commercial/blacksheep/blacksheep-1.jpg";
import automotive22 from "../images/commercial/blacksheep/blacksheep-2.jpg";
import automotive23 from "../images/commercial/blacksheep/blacksheep-5.jpg";
import automotive25 from "../images/commercial/miguel/miguel-2.jpg";
import automotive26 from "../images/commercial/miguel/miguel-4.jpg";
import automotive27 from "../images/commercial/miguel/miguel-5.jpg";
import automotive28 from "../images/commercial/miguel/miguel-6.jpg";
import automotive29 from "../images/commercial/miguel/miguel-1.jpg";

const photos = [
  {
    src: automotive1,
    width: 2,
    height: 3,
  },
  {
    src: automotive28,
    width: 2,
    height: 3,
  },
  {
    src: automotive14,
    width: 4,
    height: 5
  },
  {
    src: automotive2,
    width: 4,
    height: 3,
  },
  {
    src: automotive20,
    width: 2,
    height: 3,
  },
  {
    src: automotive3,
    width: 3,
    height: 4,
  },
  {
    src: automotive21,
    width: 3,
    height: 2,
  },
  {
    src: automotive4,
    width: 3,
    height: 2,
  },
  {
    src: automotive25,
    width: 2,
    height: 3,
  },
  {
    src: automotive5,
    width: 3,
    height: 2,
  },
  {
    src: automotive9,
    width: 4,
    height: 5,
  },
  {
    src: automotive26,
    width: 2,
    height: 3,
  },
  {
    src: automotive10,
    width: 4,
    height: 5
  },
  {
    src: automotive11,
    width: 3,
    height: 2
  },
  {
    src: automotive12,
    width: 5,
    height: 4
  },
  {
    src: automotive13,
    width: 4,
    height: 5
  },
  {
    src: automotive15,
    width: 3,
    height: 2,
  },
  {
    src: automotive16,
    width: 3,
    height: 2,
  },
  {
    src: automotive17,
    width: 3,
    height: 2,
  },
  {
    src: automotive6,
    width: 2,
    height: 3,
  },
  {
    src: automotive8,
    width: 3,
    height: 2,
  },
  {
    src: automotive18,
    width: 2,
    height: 3,
  },
  {
    src: automotive27,
    width: 2,
    height: 3,
  },
  {
    src: automotive19,
    width: 2,
    height: 3,
  },
  {
    src: automotive22,
    width: 3,
    height: 2,
  },
  {
    src: automotive29,
    width: 3,
    height: 2,
  },
  {
    src: automotive23,
    width: 4,
    height: 5,
  }
];

export default function Automotive() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Automotive">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Automotive</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Automotive"
        img={automotive18}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}