import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import interiors1 from "../images/interiors/interiors-1.jpg";
import interiors2 from "../images/interiors/interiors-2.jpg";
import interiors3 from "../images/interiors/interiors-3.jpg";
import interiors4 from "../images/interiors/interiors-4.jpg";
import interiors5 from "../images/interiors/interiors-5.jpg";
import interiors6 from "../images/interiors/interiors-6.jpg";
import interiors7 from "../images/interiors/interiors-7.jpg";
import interiors8 from "../images/interiors/interiors-8.jpg";
import interiors9 from "../images/interiors/interiors-9.jpg";
import interiors10 from "../images/interiors/interiors-10.jpg";
import interiors11 from "../images/interiors/interiors-11.jpg";
import interiors12 from "../images/interiors/interiors-12.jpg";
import interiors13 from "../images/interiors/interiors-13.jpg";
import interiors14 from "../images/interiors/interiors-14.jpg";

const photos = [
  {
    src: interiors1,
    width: 2,
    height: 3,
  },
  {
    src: interiors2,
    width: 2,
    height: 3,
  },
  {
    src: interiors3,
    width: 2,
    height: 3,
  },
  {
    src: interiors4,
    width: 2,
    height: 3,
  },
  {
    src: interiors5,
    width: 2,
    height: 3,
  },
  {
    src: interiors6,
    width: 2,
    height: 3,
  },
  {
    src: interiors7,
    width: 2,
    height: 3,
  },
  {
    src: interiors8,
    width: 2,
    height: 3,
  },
  {
    src: interiors9,
    width: 2,
    height: 3,
  },
  {
    src: interiors10,
    width: 2,
    height: 3,
  },
  {
    src: interiors11,
    width: 2,
    height: 3,
  },
  {
    src: interiors12,
    width: 2,
    height: 3,
  },
  {
    src: interiors13,
    width: 2,
    height: 3,
  },
  {
    src: interiors14,
    width: 2,
    height: 3,
  },
];

export default function RealEstate() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Interiors">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Interiors</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Interiors"
        img={interiors11}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}