import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import PageTitle from "../components/PageTitle";
import './Video.scss';

import madeira2 from "../images/commercial/madeira/madeira-2.jpg";


export default function Video() {

  return (
    <div className="Video">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Visit Video</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Video"
        img={madeira2}
      ></PageTitle>
      <div className="Page-content">
        <div className="Video-content">
              <iframe className="Video-container" src="https://player.vimeo.com/video/754310567?h=1ad07de78e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" ></iframe>
          {/* <div className="Video-container horizontal">
            <iframe src="https://streamable.com/e/zdzp2i?nocontrols=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" ></iframe>
          </div> */}
          {/* <div className="Video-container">
              <iframe src="https://streamable.com/e/67j6u6?autoplay=1&nocontrols=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:'100%',height: '100%',position:'absolute',left:0,top:0,overflow:'hidden'}}></iframe>
          </div>
          <div className="Video-container">
              <iframe src="https://streamable.com/e/txlbct?nocontrols=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:'100%',height: '100%',position:'absolute',left:0,top:0,overflow:'hidden'}}></iframe>
          </div>
          <div className="Video-container">
              <iframe src="https://streamable.com/e/txlbct?nocontrols=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:'100%',height: '100%',position:'absolute',left:0,top:0,overflow:'hidden'}}></iframe>
          </div>
          <div className="Video-container horizontal">
            <iframe src="https://streamable.com/e/zdzp2i?nocontrols=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:'100%',height: '100%',position:'absolute',left:0,top:0,overflow:'hidden'}}></iframe>
          </div> */}
        </div>
      </div>
    </div>
  );
}