import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import haura1 from "../images/fashion/haura-ss22/haura-ss22-1.jpg";
import haura2 from "../images/fashion/haura-ss22/haura-ss22-2.jpg";
import haura3 from "../images/fashion/haura-ss22/haura-ss22-3.jpg";
import haura4 from "../images/fashion/haura-ss22/haura-ss22-4.jpg";
import haura5 from "../images/fashion/haura-ss22/haura-ss22-5.jpg";
import haura6 from "../images/fashion/haura-ss22/haura-ss22-6.jpg";
import haura7 from "../images/fashion/haura-ss22/haura-ss22-7.jpg";
import haura8 from "../images/fashion/haura-ss22/haura-ss22-8.jpg";

const photos = [
  { src: haura1 },
  { src: haura2 },
  { src: haura3 },
  { src: haura4 },
  { src: haura5 },
  { src: haura6 },
  { src: haura7 },
  { src: haura8 },
].map((ph) => ({
  ...ph,
  width: 4,
  height: 5
}));

export default function HauraSS22() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Haura">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Haura</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Haura SS22"
        img={haura1}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}