import React from "react";
import { Link } from "react-router-dom";
import "./MenuLinks.scss";

export default class MenuLinks extends React.Component {
  render() {
    return (
      <div className="MenuLinks">
        {this.props.links.map((menuItem, index) => (
          <Link key={index} to={menuItem.link} className='Link'>
            <div className={`Link-name`}>{menuItem.label}</div>
            <img src={menuItem.img} className="Link-image"></img>
          </Link>
        ))}
      </div>
    );
  }
}
