import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import amaltica1 from "../images/commercial/amaltica/amaltica-1.jpg";
import amaltica2 from "../images/commercial/amaltica/amaltica-2.jpg";
import amaltica3 from "../images/commercial/amaltica/amaltica-3.jpg";
import amaltica4 from "../images/commercial/amaltica/amaltica-4.jpg";
import amaltica5 from "../images/commercial/amaltica/amaltica-5.jpg";
import amaltica6 from "../images/commercial/amaltica/amaltica-6.jpg";
import amaltica7 from "../images/commercial/amaltica/amaltica-7.jpg";
import amaltica8 from "../images/commercial/amaltica/amaltica-8.jpg";
import amaltica9 from "../images/commercial/amaltica/amaltica-9.jpg";
import amaltica10 from "../images/commercial/amaltica/amaltica-10.jpg";
import amaltica11 from "../images/commercial/amaltica/amaltica-11.jpg";
import amaltica12 from "../images/commercial/amaltica/amaltica-12.jpg";
import amaltica13 from "../images/commercial/amaltica/amaltica-13.jpg";
import amaltica14 from "../images/commercial/amaltica/amaltica-14.jpg";

const photos = [
  {
    src: amaltica1,
  },
  {
    src: amaltica2,
  },
  {
    src: amaltica3,
  },
  {
    src: amaltica4,
  },
  {
    src: amaltica5,
  },
  {
    src: amaltica6,
  },
  {
    src: amaltica7,
  },
  {
    src: amaltica8,
  },
  {
    src: amaltica9,
  },
  {
    src: amaltica10,
  },
  {
    src: amaltica11,
  },
  {
    src: amaltica12,
  },
  {
    src: amaltica13,
  },
  {
    src: amaltica14,
  },
].map((photo) => ({
  ...photo,
  width: 4,
  height: 5,
  sizes: ["(min-width: 600px) 90vw,(min-width: 1024px) 33.3vw,100vw"],
}));

export default function Amaltica() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Amaltica">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Amaltica</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Amaltica"
        img={amaltica9}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}