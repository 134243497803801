import React from "react";
import "./PageTitle.scss";

export default function PageTitle(props) {
  return (
    <div className={`PageTitle`}>
        <div className="PageTitle-label">{props.label}</div>
        <img className="PageTitle-image" src={props.img}></img>
    </div>
  );
}