import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import amv1 from "../images/commercial/amv/amv-1.jpg";
import amv2 from "../images/commercial/amv/amv-2.jpg";
import amv3 from "../images/commercial/amv/amv-3.jpg";
import amv4 from "../images/commercial/amv/amv-4.jpg";
import amv5 from "../images/commercial/amv/amv-5.jpg";
import amv6 from "../images/commercial/amv/amv-6.jpg";
import amv7 from "../images/commercial/amv/amv-7.jpg";
import amv8 from "../images/commercial/amv/amv-8.jpg";
import amv9 from "../images/commercial/amv/amv-9.jpg";
import amv10 from "../images/commercial/amv/amv-10.jpg";
import amv11 from "../images/commercial/amv/amv-11.jpg";
import amv12 from "../images/commercial/amv/amv-12.jpg";

const photos = [
  {
    src: amv1,
    width: 2,
    height: 3,
  },
  {
    src: amv2,
    width: 2,
    height: 3,
  },
  {
    src: amv7,
    width: 2,
    height: 3,
  },
  {
    src: amv3,
    width: 2,
    height: 3,
  },
  {
    src: amv4,
    width: 2,
    height: 3,
  },
  {
    src: amv5,
    width: 2,
    height: 3,
  },
  {
    src: amv6,
    width: 2,
    height: 3,
  },
  {
    src: amv8,
    width: 2,
    height: 3,
  },
  {
    src: amv9,
    width: 2,
    height: 3,
  },
  {
    src: amv10,
    width: 2,
    height: 3,
  },
  {
    src: amv11,
    width: 2,
    height: 3,
  },
  {
    src: amv12,
    width: 2,
    height: 3,
  }
];

export default function AmericanVintage() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="AmericanVintage">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | American Vintage</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="American Vintage"
        img={amv2}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}