import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import ScrollTop from './ScrollTop';
import Header from "./components/Header";
import Footer from "./components/Footer";

import About from "./pages/About";
import Amaltica from "./pages/Amaltica";
import AmericanVintage from "./pages/AmericanVintage";
import Audi from "./pages/Audi";
import Autatlantis from "./pages/Autatlantis";
import Automotive from "./pages/Automotive";
import Azores from "./pages/Azores";
import Balkan from "./pages/Balkan";
import Bodum from "./pages/Bodum";
import Commercial from "./pages/Commercial";
import DadeliaFW21 from "./pages/DadeliaFW21";
import DadeliaSS22 from "./pages/DadeliaSS22";
import Experience from "./pages/Experience";
import Fashion from "./pages/Fashion";
import Interiors from "./pages/Interiors";
import Film from "./pages/Film";
import HauraSS22 from "./pages/HauraSS22";
import Madeira from "./pages/Madeira";
import Marloe from "./pages/Marloe";
import Outdoor from "./pages/Outdoor";
import Portfolio from "./pages/Portfolio";
import RealEstate from "./pages/RealEstate";
import SofiaCostaSS22 from "./pages/SofiaCostaSS22";
import Timberland from "./pages/Timberland";
import Transparent from "./pages/Transparent";
import Urbanista from "./pages/Urbanista";
import VinceroWatch from "./pages/VinceroWatch";
import Video from "./pages/Video";
import Weddings from "./pages/Weddings";

import GA from './GoogleAnalytics';

import "./App.scss";
import "./clear.scss";

export default function App() {
  return (
    <Router>
      <ScrollTop>
        { GA.init() && <GA.RouteTracker /> }
        <div className="App">
          <link
            href="https://fonts.googleapis.com/css2?family=Abel&display=swap"
            rel="stylesheet"
          ></link>
          <link
            href="https://fonts.googleapis.com/css2?family=Arimo&display=swap"
            rel="stylesheet"
          ></link>
          <Header></Header>

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <div className="App-content">
            <Switch>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/amaltica">
                <Amaltica />
              </Route>
              <Route path="/american-vintage">
                <AmericanVintage />
              </Route>
              <Route path="/audi">
                <Audi />
              </Route>
              <Route path="/autatlantis">
                <Autatlantis />
              </Route>
              <Route path="/automotive">
                <Automotive />
              </Route>
              <Route path="/balkan-campers">
                <Balkan />
              </Route>
              <Route path="/bodum">
                <Bodum />
              </Route>
              <Route path="/commercial">
                <Commercial />
              </Route>
              <Route path="/dadelia-ss22">
                <DadeliaSS22 />
              </Route>
              <Route path="/dadelia-fw21">
                <DadeliaFW21 />
              </Route>
              <Route path="/experience-oc">
                <Experience />
              </Route>
              <Route path="/fashion">
                <Fashion />
              </Route>
              <Route path="/interiors">
                <Interiors />
              </Route>
              <Route path="/haura-ss22">
                <HauraSS22 />
              </Route>
              <Route path="/film">
                <Film />
              </Route>
              <Route path="/marloe">
                <Marloe />
              </Route>
              <Route path="/newsletter" component={() => {
                window.location.href = 'http://eepurl.com/h7H8NX';
                return null;
              }}/>
              <Route path="/outdoor">
                <Outdoor />
              </Route>
              <Route path="/portfolio">
                <Portfolio />
              </Route>
              <Route path="/real-estate">
                <RealEstate />
              </Route>
              <Route path="/timberland">
                <Timberland />
              </Route>
              <Route path="/sofiacosta-ss22">
                <SofiaCostaSS22 />
              </Route>
              <Route path="/transparent">
                <Transparent />
              </Route>
              <Route path="/urbanista">
                <Urbanista />
              </Route>
              <Route path="/visit-azores">
                <Azores />
              </Route>
              <Route path="/visit-madeira">
                <Madeira />
              </Route>
              <Route path="/video">
                <Video />
              </Route>
              <Route path="/vincero">
                <VinceroWatch />
              </Route>
              <Route path="/weddings" component={() => {
                window.location.href = 'https://notyourtypicalphoto.pt';
                return null;
              }}/>
              <Route path="/workshops" component={() => {
                window.location.href = 'https://joaofalcao.bigcartel.com/category/workshops';
                return null;
              }}/>
              <Route path="/presets" component={() => {
                window.location.href = 'https://joaofalcao.bigcartel.com/category/presets';
                return null;
              }}/>
              <Route path="/prints" component={() => {
                window.location.href = 'https://joaofalcao.bigcartel.com/category/prints';
                return null;
              }}/>
              <Route path="/">
                <Redirect to="/portfolio" />
              </Route>
            </Switch>
          </div>
          <Footer></Footer>
        </div>
      </ScrollTop>
    </Router>
  );
}
