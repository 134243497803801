import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import dadelia1 from "../images/fashion/dadelia-ss22/dadelia-ss22-1.jpg";
import dadelia2 from "../images/fashion/dadelia-ss22/dadelia-ss22-2.jpg";
import dadelia3 from "../images/fashion/dadelia-ss22/dadelia-ss22-3.jpg";
import dadelia4 from "../images/fashion/dadelia-ss22/dadelia-ss22-4.jpg";
import dadelia5 from "../images/fashion/dadelia-ss22/dadelia-ss22-5.jpg";
import dadelia6 from "../images/fashion/dadelia-ss22/dadelia-ss22-6.jpg";
import dadelia7 from "../images/fashion/dadelia-ss22/dadelia-ss22-7.jpg";
import dadelia8 from "../images/fashion/dadelia-ss22/dadelia-ss22-8.jpg";
import dadelia9 from "../images/fashion/dadelia-ss22/dadelia-ss22-9.jpg";
import dadelia10 from "../images/fashion/dadelia-ss22/dadelia-ss22-10.jpg";
import dadelia11 from "../images/fashion/dadelia-ss22/dadelia-ss22-11.jpg";
import dadelia12 from "../images/fashion/dadelia-ss22/dadelia-ss22-12.jpg";
import dadelia13 from "../images/fashion/dadelia-ss22/dadelia-ss22-13.jpg";
import dadelia14 from "../images/fashion/dadelia-ss22/dadelia-ss22-14.jpg";
import dadelia15 from "../images/fashion/dadelia-ss22/dadelia-ss22-15.jpg";
import dadelia16 from "../images/fashion/dadelia-ss22/dadelia-ss22-16.jpg";
import dadelia17 from "../images/fashion/dadelia-ss22/dadelia-ss22-17.jpg";
import dadelia18 from "../images/fashion/dadelia-ss22/dadelia-ss22-18.jpg";

const photos = [
  { src: dadelia1 },
  { src: dadelia2 },
  { src: dadelia7 },
  { src: dadelia3 },
  { src: dadelia4 },
  { src: dadelia5 },
  { src: dadelia6 },
  { src: dadelia8 },
  { src: dadelia9 },
  { src: dadelia10 },
  { src: dadelia11 },
  { src: dadelia12 },
  { src: dadelia13 },
  { src: dadelia14 },
  { src: dadelia15 },
  { src: dadelia16 },
  { src: dadelia17 },
  { src: dadelia18 }
].map((ph) => ({
  ...ph,
  width: 4,
  height: 5
}));

export default function DadeliaS22() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Dadelia">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | D'Adélia</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="D'Adélia SS22"
        img={dadelia1}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}