import React from "react";
import MetaTags from 'react-meta-tags';
import MenuLinks from "../components/MenuLinks";
import { useTranslation } from 'react-i18next';

import sofiacostass22 from "../images/fashion/sofiacosta-ss22/sofiacosta-ss22-12.jpg";
import dadeliass22 from "../images/fashion/dadelia-ss22/dadelia-ss22-11.jpg";
import dadeliafw21 from "../images/fashion/dadelia-fw21/dadelia-fw21-1.jpg";
import haurass22 from "../images/fashion/haura-ss22/haura-ss22-6.jpg";

const links = [
  {
    label: "Sofia Costa SS2",
    link: "/sofiacosta-ss22",
    img: sofiacostass22,
  },
  {
    label: "D'Adélia SS22",
    link: "/dadelia-ss22",
    img: dadeliass22,
  },
  {
    label: "D'Adélia FW21",
    link: "/dadelia-fw21",
    img: dadeliafw21,
  },
  {
    label: "Haura",
    link: "/haura-ss22",
    img: haurass22,
  }
];

export default function Fashion() {
  const { t, i18n } = useTranslation();

  return (
    <div className="Fashion">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Fashion</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <div className="Page-title">{t('Fashion')}</div>
      <MenuLinks links={links}></MenuLinks>
    </div>
  );
}