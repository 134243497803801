import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Brands": "Some brands I've worked with",
      "About1": "João is an outdoor and commercial photographer with a big focus on storytelling and documenting them through images. Storytelling is a key element he emphasizes through his work. It’s about the bigger picture and journey as a whole. He currently lives in Porto, Portugal, but travels anywhere to get the job done.",
      "AboutQuote": "Create to inspire and explore your daily boundaries.",
      "Outdoor": "Outdoor",
      "Commercial": "Commercial",
      "Workshops": "Workshops",
      "Presets": "Presets",
      "Prints": "Prints",
      "About": "About",
      "Contacts": "Contact me!",
      "Email": "Email",
      "Address": "Address",
      "Phone": "Phone",
      "Insurance": "IATI Insurance",
      "Weddings": "Weddings",
      "Fashion": "Fashion",
      "Film": "Film",
      "Interiors": "Interiors",
      "Automotive": "Automotive"
    }
  },
  pt: {
    translation: {
      "Brands": "Algumas das marcas com que trabalhei",
      "About1": "O João é um fotógrafo de natureza e comercial com grande foco no storytelling e documentar através de fotografias. Storytelling é o elemento que tenta enfatizar através do seu trabalho. É sobre contar a história toda e não apenas o resultado final. Vive atualmente no Porto, Portugal, mas viaja pelo mundo para tornar o trabalho possível.",
      "AboutQuote": "Criar para inspirar e explorar os limites.",
      "Outdoor": "Natureza",
      "Commercial": "Comercial",
      "Workshops": "Workshops",
      "Presets": "Presets",
      "Prints": "Prints",
      "About": "Sobre",
      "Contacts": "Entrar em contacto!",
      "Email": "Email",
      "Address": "Morada",
      "Phone": "Telemóvel",
      "Insurance": "Seguro IATI",
      "Weddings": "Casamentos",
      "Fashion": "Moda",
      "Film": "Analógico",
      "Interiors": "Interiores",
      "Automotive": "Automóvel"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "pt",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;