import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import interiors1 from "../images/interiors/interiors-1.jpg";
import interiors2 from "../images/interiors/interiors-2.jpg";
import interiors3 from "../images/interiors/interiors-3.jpg";
import interiors4 from "../images/interiors/interiors-4.jpg";
import interiors5 from "../images/interiors/interiors-5.jpg";
import interiors6 from "../images/interiors/interiors-6.jpg";
import interiors7 from "../images/interiors/interiors-7.jpg";
import interiors8 from "../images/interiors/interiors-8.jpg";
import interiors9 from "../images/interiors/interiors-9.jpg";
import interiors10 from "../images/interiors/interiors-10.jpg";
import interiors11 from "../images/interiors/interiors-11.jpg";
import interiors12 from "../images/interiors/interiors-12.jpg";
import interiors13 from "../images/interiors/interiors-13.jpg";
import interiors14 from "../images/interiors/interiors-14.jpg";
import realEstate1 from "../images/real-estate/real-estate-1.jpg";
import realEstate2 from "../images/real-estate/real-estate-2.jpg";
import realEstate3 from "../images/real-estate/real-estate-3.jpg";
import realEstate4 from "../images/real-estate/real-estate-4.jpg";
import realEstate5 from "../images/real-estate/real-estate-5.jpg";
import realEstate6 from "../images/real-estate/real-estate-6.jpg";
import realEstate7 from "../images/real-estate/real-estate-7.jpg";
import realEstate8 from "../images/real-estate/real-estate-8.jpg";
import realEstate9 from "../images/real-estate/real-estate-9.jpg";
import realEstate10 from "../images/real-estate/real-estate-10.jpg";
import realEstate11 from "../images/real-estate/real-estate-11.jpg";
import realEstate12 from "../images/real-estate/real-estate-12.jpg";
import realEstate13 from "../images/real-estate/real-estate-13.jpg";
import realEstate14 from "../images/real-estate/real-estate-14.jpg";
import realEstate15 from "../images/real-estate/real-estate-15.jpg";
import realEstate16 from "../images/real-estate/real-estate-16.jpg";
import realEstate17 from "../images/real-estate/real-estate-17.jpg";
import realEstate18 from "../images/real-estate/real-estate-18.jpg";
import realEstate19 from "../images/real-estate/real-estate-19.jpg";
import realEstate20 from "../images/real-estate/real-estate-20.jpg";
import realEstate21 from "../images/real-estate/real-estate-21.jpg";
import realEstate22 from "../images/real-estate/real-estate-22.jpg";
import realEstate23 from "../images/real-estate/real-estate-23.jpg";
import realEstate24 from "../images/real-estate/real-estate-24.jpg";
import realEstate25 from "../images/real-estate/real-estate-25.jpg";
import realEstate26 from "../images/real-estate/real-estate-26.jpg";
import realEstate27 from "../images/real-estate/real-estate-27.jpg";
import realEstate28 from "../images/real-estate/real-estate-28.jpg";
import realEstate29 from "../images/real-estate/real-estate-29.jpg";
import realEstate32 from "../images/real-estate/real-estate-32.jpg";
import realEstate34 from "../images/real-estate/real-estate-34.jpg";
import realEstate35 from "../images/real-estate/real-estate-35.jpg";
import realEstate36 from "../images/real-estate/real-estate-36.jpg";
import realEstate37 from "../images/real-estate/real-estate-37.jpg";
import realEstate38 from "../images/real-estate/real-estate-38.jpg";
import realEstate39 from "../images/real-estate/real-estate-39.jpg";
import realEstate40 from "../images/real-estate/real-estate-40.jpg";
import realEstate41 from "../images/real-estate/real-estate-41.jpg";
import realEstate43 from "../images/real-estate/real-estate-43.jpg";
import realEstate44 from "../images/real-estate/real-estate-44.jpg";

const photos = [
  {
    src: interiors1,
    width: 2,
    height: 3,
  },
  {
    src: interiors2,
    width: 2,
    height: 3,
  },
  {
    src: interiors3,
    width: 2,
    height: 3,
  },
  {
    src: interiors4,
    width: 2,
    height: 3,
  },
  {
    src: interiors5,
    width: 2,
    height: 3,
  },
  {
    src: interiors6,
    width: 2,
    height: 3,
  },
  {
    src: interiors7,
    width: 2,
    height: 3,
  },
  {
    src: interiors8,
    width: 2,
    height: 3,
  },
  {
    src: interiors9,
    width: 2,
    height: 3,
  },
  {
    src: interiors10,
    width: 2,
    height: 3,
  },
  {
    src: interiors11,
    width: 2,
    height: 3,
  },
  {
    src: interiors12,
    width: 2,
    height: 3,
  },
  {
    src: interiors13,
    width: 2,
    height: 3,
  },
  {
    src: interiors14,
    width: 2,
    height: 3,
  },{
    src: realEstate26,
    width: 3,
    height: 2,
  },
  {
    src: realEstate27,
    width: 3,
    height: 2,
  },
  {
    src: realEstate28,
    width: 2,
    height: 3,
  },
  {
    src: realEstate29,
    width: 2,
    height: 3,
  },
  {
    src: realEstate32,
    width: 2,
    height: 3,
  },
  {
    src: realEstate34,
    width: 2,
    height: 3,
  },
  {
    src: realEstate35,
    width: 2,
    height: 3,
  },
  {
    src: realEstate36,
    width: 2,
    height: 3,
  },
  {
    src: realEstate37,
    width: 2,
    height: 3,
  },
  {
    src: realEstate38,
    width: 2,
    height: 3,
  },
  {
    src: realEstate39,
    width: 2,
    height: 3,
  },
  {
    src: realEstate40,
    width: 2,
    height: 3,
  },
  {
    src: realEstate41,
    width: 2,
    height: 3,
  },
  {
    src: realEstate43,
    width: 2,
    height: 3,
  },
  {
    src: realEstate44,
    width: 2,
    height: 3,
  },
  {
    src: realEstate1,
    width: 3,
    height: 2,
  },
  {
    src: realEstate2,
    width: 3,
    height: 2,
  },
  {
    src: realEstate3,
    width: 3,
    height: 2,
  },
  {
    src: realEstate4,
    width: 3,
    height: 2,
  },
  {
    src: realEstate5,
    width: 2,
    height: 3,
  },
  {
    src: realEstate6,
    width: 3,
    height: 2,
  },
  {
    src: realEstate7,
    width: 3,
    height: 2,
  },
  {
    src: realEstate8,
    width: 3,
    height: 2,
  },
  {
    src: realEstate9,
    width: 3,
    height: 2,
  },
  {
    src: realEstate10,
    width: 3,
    height: 2,
  },
  {
    src: realEstate11,
    width: 3,
    height: 2,
  },
  {
    src: realEstate12,
    width: 3,
    height: 2,
  },
  {
    src: realEstate13,
    width: 3,
    height: 2,
  },
  {
    src: realEstate14,
    width: 3,
    height: 2,
  },
  {
    src: realEstate15,
    width: 3,
    height: 2,
  },
  {
    src: realEstate16,
    width: 3,
    height: 2,
  },
  {
    src: realEstate17,
    width: 3,
    height: 2,
  },
  {
    src: realEstate18,
    width: 3,
    height: 2,
  },
  {
    src: realEstate19,
    width: 2,
    height: 3,
  },
  {
    src: realEstate20,
    width: 3,
    height: 2,
  },
  {
    src: realEstate21,
    width: 3,
    height: 2,
  },
  {
    src: realEstate22,
    width: 2,
    height: 3,
  },
  {
    src: realEstate23,
    width: 3,
    height: 2,
  },
  {
    src: realEstate24,
    width: 2,
    height: 3,
  },
  {
    src: realEstate25,
    width: 2,
    height: 3,
  },
];

export default function RealEstate() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="RealEstate">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Real Estate</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Real Estate"
        img={realEstate5}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}