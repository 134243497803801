import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import transparent1 from "../images/commercial/transparent/transparent-1.jpg";
import transparent2 from "../images/commercial/transparent/transparent-2.jpg";
import transparent3 from "../images/commercial/transparent/transparent-3.jpg";
import transparent4 from "../images/commercial/transparent/transparent-4.jpg";
import transparent5 from "../images/commercial/transparent/transparent-5.jpg";
import transparent6 from "../images/commercial/transparent/transparent-6.jpg";
import transparent7 from "../images/commercial/transparent/transparent-7.jpg";
import transparent8 from "../images/commercial/transparent/transparent-8.jpg";
import transparent9 from "../images/commercial/transparent/transparent-9.jpg";
import transparent10 from "../images/commercial/transparent/transparent-10.jpg";
import transparent11 from "../images/commercial/transparent/transparent-11.jpg";
import transparent12 from "../images/commercial/transparent/transparent-12.jpg";

const photos = [
  {
    src: transparent1,
    width: 2,
    height: 3,
  },
  {
    src: transparent2,
    width: 2,
    height: 3,
  },
  {
    src: transparent7,
    width: 3,
    height: 2,
  },
  {
    src: transparent3,
    width: 2,
    height: 3,
  },
  {
    src: transparent4,
    width: 2,
    height: 3,
  },
  {
    src: transparent5,
    width: 2,
    height: 3,
  },
  {
    src: transparent6,
    width: 3,
    height: 2,
  },
  {
    src: transparent8,
    width: 2,
    height: 3,
  },
  {
    src: transparent9,
    width: 2,
    height: 3,
  },
  {
    src: transparent10,
    width: 2,
    height: 3,
  },
  {
    src: transparent11,
    width: 2,
    height: 3,
  },
  {
    src: transparent12,
    width: 2,
    height: 3,
  }
];

export default function Transparent() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Transparent">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Transparent</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Transparent"
        img={transparent7}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}