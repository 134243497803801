import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import audi1 from "../images/commercial/audi/audi-1.jpg";
import audi2 from "../images/commercial/audi/audi-2.jpg";
import audi3 from "../images/commercial/audi/audi-3.jpg";
import audi4 from "../images/commercial/audi/audi-4.jpg";
import audi5 from "../images/commercial/audi/audi-5.jpg";
import audi6 from "../images/commercial/audi/audi-6.jpg";
import audi7 from "../images/commercial/audi/audi-7.jpg";
import audi8 from "../images/commercial/audi/audi-8.jpg";
import audi9 from "../images/commercial/audi/audi-9.jpg";
import audi10 from "../images/commercial/audi/audi-10.jpg";

const photos = [
  {
    src: audi5,
    width: 4,
    height: 3,
  },
  {
    src: audi3,
    width: 2,
    height: 3,
  },
  {
    src: audi7,
    width: 3,
    height: 2,
  },
  {
    src: audi2,
    width: 4,
    height: 3,
  },
  {
    src: audi6,
    width: 3,
    height: 4,
  },
  {
    src: audi8,
    width: 2,
    height: 3,
  },
  {
    src: audi9,
    width: 3,
    height: 2,
  },
  {
    src: audi4,
    width: 4,
    height: 3,
  },
  {
    src: audi1,
    width: 4,
    height: 3,
  },
  {
    src: audi10,
    width: 2,
    height: 3,
  },
];

export default function Audi() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Audi">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Audi</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Audi"
        img={audi9}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}