import React from "react";
import { NavLink, Link } from "react-router-dom";
import "./Header.scss";
import i18n from '../i18n'
import logo from "../images/logo.png";
import hamburguer from "../images/hamburguer.svg";
import close from "../images/close.svg";
import HeaderLinks from "./HeaderLinks";

export default class Header extends React.Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  }

  render() {
    return (
      <header>
        <div className="Header-desktop">
          <Link className="Header-home" to="/">
            <div className="Header-logo">João Falcão</div>
          </Link>
          <ul className="Menu">
            <HeaderLinks></HeaderLinks>
          </ul>
          <div className="language-picker">
            <span onClick={()=>i18n.changeLanguage('pt')}>PT </span>|<span onClick={()=>i18n.changeLanguage('en')}> EN</span>
          </div>
        </div>
        <div className="Header-mobile">
          <div className="Header-nav">
            <Link to="/">
              <div className="Header-logo">João Falcão</div>
            </Link>
            <div onClick={this.showMenu}>
              <img className="Menu-icon" src={hamburguer}></img>
            </div>
          </div>
          <div className={this.state.showMenu ? "Modal Modal-open" : "Modal"}>
            <ul className="Menu-items">
              <HeaderLinks></HeaderLinks>
            </ul>
            <button className="Close">
              {" "}
              <img src={close}></img>{" "}
            </button>
            <div className="language-picker">
              <span onClick={()=>i18n.changeLanguage('pt')}>PT </span>|<span onClick={()=>i18n.changeLanguage('en')}> EN</span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
