import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function HeaderLinks() {
  const { t, i18n } = useTranslation();

  const menuLinks = [
    {
      label: t('Portfolio'),
      link: "/portfolio",
    },
    {
      href: true,
      label: t('Workshops'),
      link: "https://joaofalcao.bigcartel.com/category/workshops",
    },
    {
      href: true,
      label: t('Weddings'),
      link: "https://notyourtypicalphoto.pt",
    },
    {
      href: true,
      label: t('Presets'),
      link: "https://joaofalcao.bigcartel.com/category/presets",
    },
    {
      href: true,
      label: t('Prints'),
      link: "https://joaofalcao.bigcartel.com/category/prints",
    },
    {
      href: true,
      label: t('Insurance'),
      link: "http://www.iatiseguros.pt/seguros-viagens?r=85480826717124",
    },
    {
      href: true,
      label: t('Newsletter'),
      link: "http://eepurl.com/h7H8NX",
    },
    {
      label: t('About'),
      link: "/about",
    },
  ];

  return (menuLinks.map((menuItem, index) => (
      <li key={index}>
        { menuItem.href ?
          <a href={menuItem.link}>{menuItem.label}</a>
          :
          <NavLink activeClassName="active" to={menuItem.link}>{menuItem.label}</NavLink>
        }
      </li>
    )));
}