import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import bodum2 from "../images/commercial/bodum/bodum-2.jpg";
import bodum3 from "../images/commercial/bodum/bodum-3.jpg";
import bodum4 from "../images/commercial/bodum/bodum-4.jpg";
import bodum5 from "../images/commercial/bodum/bodum-5.jpg";
import bodum6 from "../images/commercial/bodum/bodum-6.jpg";
import bodum7 from "../images/commercial/bodum/bodum-7.jpg";
import bodum8 from "../images/commercial/bodum/bodum-8.jpg";
import bodum9 from "../images/commercial/bodum/bodum-9.jpg";
import bodum10 from "../images/commercial/bodum/bodum-10.jpg";
import bodum11 from "../images/commercial/bodum/bodum-11.jpg";
import bodum12 from "../images/commercial/bodum/bodum-12.jpg";
import bodum13 from "../images/commercial/bodum/bodum-13.jpg";
import bodum14 from "../images/commercial/bodum/bodum-14.jpg";
import bodum15 from "../images/commercial/bodum/bodum-15.jpg";
import bodum16 from "../images/commercial/bodum/bodum-16.jpg";
import bodum17 from "../images/commercial/bodum/bodum-17.jpg";
import bodum18 from "../images/commercial/bodum/bodum-18.jpg";
import bodum20 from "../images/commercial/bodum/bodum-20.jpg";
import bodum21 from "../images/commercial/bodum/bodum-21.jpg";
import bodum22 from "../images/commercial/bodum/bodum-22.jpg";

const photos = [
  {
    src: bodum3,
    width: 2,
    height: 3,
  },
  {
    src: bodum4,
    width: 2,
    height: 3,
  },
  {
    src: bodum7,
    width: 2,
    height: 3,
  },
  {
    src: bodum11,
    width: 3,
    height: 2,
  },
  {
    src: bodum2,
    width: 2,
    height: 3,
  },
  {
    src: bodum5,
    width: 3,
    height: 2,
  },
  {
    src: bodum6,
    width: 2,
    height: 3,
  },
  {
    src: bodum8,
    width: 2,
    height: 3,
  },
  {
    src: bodum16,
    width: 4,
    height: 5,
  },
  {
    src: bodum9,
    width: 2,
    height: 3,
  },
  {
    src: bodum12,
    width: 2,
    height: 3,
  },
  {
    src: bodum13,
    width: 2,
    height: 3,
  },
  {
    src: bodum14,
    width: 2,
    height: 3,
  },
  {
    src: bodum15,
    width: 2,
    height: 3,
  },
  {
    src: bodum17,
    width: 2,
    height: 3,
  },
  {
    src: bodum18,
    width: 2,
    height: 3,
  },
  {
    src: bodum20,
    width: 3,
    height: 2,
  },
  {
    src: bodum10,
    width: 2,
    height: 3,
  },
  {
    src: bodum21,
    width: 2,
    height: 3,
  },
  {
    src: bodum22,
    width: 3,
    height: 2,
  },
];

export default function Bodum() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Bodum">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Bodum</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Bodum"
        img={bodum7}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}