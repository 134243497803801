import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import experienceoc1 from "../images/commercial/experienceoc/experienceoc-1.jpg";
import experienceoc2 from "../images/commercial/experienceoc/experienceoc-2.jpg";
import experienceoc3 from "../images/commercial/experienceoc/experienceoc-3.jpg";
import experienceoc4 from "../images/commercial/experienceoc/experienceoc-4.jpg";
import experienceoc5 from "../images/commercial/experienceoc/experienceoc-5.jpg";
import experienceoc6 from "../images/commercial/experienceoc/experienceoc-6.jpg";
import experienceoc7 from "../images/commercial/experienceoc/experienceoc-7.jpg";
import experienceoc8 from "../images/commercial/experienceoc/experienceoc-8.jpg";
import experienceoc9 from "../images/commercial/experienceoc/experienceoc-9.jpg";
import experienceoc10 from "../images/commercial/experienceoc/experienceoc-10.jpg";
import experienceoc11 from "../images/commercial/experienceoc/experienceoc-11.jpg";
import experienceoc12 from "../images/commercial/experienceoc/experienceoc-12.jpg";
import experienceoc13 from "../images/commercial/experienceoc/experienceoc-13.jpg";
import experienceoc14 from "../images/commercial/experienceoc/experienceoc-14.jpg";
import experienceoc15 from "../images/commercial/experienceoc/experienceoc-15.jpg";
import experienceoc17 from "../images/commercial/experienceoc/experienceoc-17.jpg";
import experienceoc18 from "../images/commercial/experienceoc/experienceoc-18.jpg";
import experienceoc19 from "../images/commercial/experienceoc/experienceoc-19.jpg";
import experienceoc20 from "../images/commercial/experienceoc/experienceoc-20.jpg";
import experienceoc22 from "../images/commercial/experienceoc/experienceoc-22.jpg";

const photos = [
  {
    src: experienceoc1,
    width: 4,
    height: 3,
  },
  {
    src: experienceoc2,
    width: 4,
    height: 3,
  },
  {
    src: experienceoc3,
    width: 4,
    height: 5,
  },
  {
    src: experienceoc4,
    width: 4,
    height: 5,
  },
  {
    src: experienceoc5,
    width: 4,
    height: 5,
  },
  {
    src: experienceoc6,
    width: 4,
    height: 5,
  },
  {
    src: experienceoc7,
    width: 4,
    height: 5,
  },
  {
    src: experienceoc8,
    width: 4,
    height: 5,
  },
  {
    src: experienceoc9,
    width: 3,
    height: 2,
  },
  {
    src: experienceoc10,
    width: 4,
    height: 5,
  },
  {
    src: experienceoc11,
    width: 4,
    height: 3,
  },
  {
    src: experienceoc12,
    width: 3,
    height: 2,
  },
  {
    src: experienceoc13,
    width: 4,
    height: 5,
  },
  {
    src: experienceoc14,
    width: 3,
    height: 2,
  },
  {
    src: experienceoc15,
    width: 3,
    height: 2,
  },
  {
    src: experienceoc17,
    width: 4,
    height: 5,
  },
  {
    src: experienceoc18,
    width: 4,
    height: 3,
  },
  {
    src: experienceoc19,
    width: 4,
    height: 5,
  },
  {
    src: experienceoc20,
    width: 4,
    height: 3,
  },
  {
    src: experienceoc22,
    width: 2,
    height: 3,
  },
];

export default function ExperienceOC() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="ExperienceOC">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Experience OC</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Experience OC"
        img={experienceoc17}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}