import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import dadelia1 from "../images/fashion/dadelia-fw21/dadelia-fw21-1.jpg";
import dadelia2 from "../images/fashion/dadelia-fw21/dadelia-fw21-2.jpg";
import dadelia3 from "../images/fashion/dadelia-fw21/dadelia-fw21-3.jpg";
import dadelia4 from "../images/fashion/dadelia-fw21/dadelia-fw21-4.jpg";
import dadelia5 from "../images/fashion/dadelia-fw21/dadelia-fw21-5.jpg";
import dadelia6 from "../images/fashion/dadelia-fw21/dadelia-fw21-6.jpg";
import dadelia7 from "../images/fashion/dadelia-fw21/dadelia-fw21-7.jpg";
import dadelia8 from "../images/fashion/dadelia-fw21/dadelia-fw21-8.jpg";
import dadelia10 from "../images/fashion/dadelia-fw21/dadelia-fw21-10.jpg";
import dadelia12 from "../images/fashion/dadelia-fw21/dadelia-fw21-12.jpg";
import dadelia13 from "../images/fashion/dadelia-fw21/dadelia-fw21-13.jpg";
import dadelia14 from "../images/fashion/dadelia-fw21/dadelia-fw21-14.jpg";
import dadelia15 from "../images/fashion/dadelia-fw21/dadelia-fw21-15.jpg";
import dadelia16 from "../images/fashion/dadelia-fw21/dadelia-fw21-16.jpg";
import dadelia17 from "../images/fashion/dadelia-fw21/dadelia-fw21-17.jpg";
import dadelia18 from "../images/fashion/dadelia-fw21/dadelia-fw21-18.jpg";
import dadelia19 from "../images/fashion/dadelia-fw21/dadelia-fw21-19.jpg";
import dadelia20 from "../images/fashion/dadelia-fw21/dadelia-fw21-20.jpg";
import dadelia21 from "../images/fashion/dadelia-fw21/dadelia-fw21-21.jpg";
import dadelia22 from "../images/fashion/dadelia-fw21/dadelia-fw21-22.jpg";
import dadelia23 from "../images/fashion/dadelia-fw21/dadelia-fw21-23.jpg";
import dadelia24 from "../images/fashion/dadelia-fw21/dadelia-fw21-24.jpg";
import dadelia25 from "../images/fashion/dadelia-fw21/dadelia-fw21-25.jpg";
import dadelia26 from "../images/fashion/dadelia-fw21/dadelia-fw21-26.jpg";
import dadelia27 from "../images/fashion/dadelia-fw21/dadelia-fw21-27.jpg";
import dadelia28 from "../images/fashion/dadelia-fw21/dadelia-fw21-28.jpg";
import dadelia29 from "../images/fashion/dadelia-fw21/dadelia-fw21-29.jpg";
import dadelia30 from "../images/fashion/dadelia-fw21/dadelia-fw21-30.jpg";
import dadelia31 from "../images/fashion/dadelia-fw21/dadelia-fw21-31.jpg";
import dadelia32 from "../images/fashion/dadelia-fw21/dadelia-fw21-32.jpg";
import dadelia33 from "../images/fashion/dadelia-fw21/dadelia-fw21-33.jpg";
import dadelia34 from "../images/fashion/dadelia-fw21/dadelia-fw21-34.jpg";
import dadelia35 from "../images/fashion/dadelia-fw21/dadelia-fw21-35.jpg";

const photos = [
  { src: dadelia1 },
  { src: dadelia2 },
  { src: dadelia7 },
  { src: dadelia3 },
  { src: dadelia4 },
  { src: dadelia5 },
  { src: dadelia6 },
  { src: dadelia8 },
  { src: dadelia10 },
  { src: dadelia12 },
  { src: dadelia13 },
  { src: dadelia14 },
  { src: dadelia15 },
  { src: dadelia16 },
  { src: dadelia17 },
  { src: dadelia18 },
  { src: dadelia19 },
  { src: dadelia20 },
  { src: dadelia21 },
  { src: dadelia22 },
  { src: dadelia23 },
  { src: dadelia24 },
  { src: dadelia25 },
  { src: dadelia26 },
  { src: dadelia27 },
  { src: dadelia28 },
  { src: dadelia29 },
  { src: dadelia30 },
  { src: dadelia31 },
  { src: dadelia32 },
  { src: dadelia33 },
  { src: dadelia34 },
  { src: dadelia35 }
].map((ph) => ({
  ...ph,
  width: 4,
  height: 5
}));

export default function DadeliaS22() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Dadelia">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | D'Adélia</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="D'Adélia FW21"
        img={dadelia1}
      ></PageTitle>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}