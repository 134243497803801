import React from "react";
import MetaTags from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import './About.scss';

import joao from "../images/joao.jpg";

import azores from "../images/brands/azores.png";
import audi from "../images/brands/audi.png";
import timberland from "../images/brands/timberland.png";
import sumol from "../images/brands/sumol.png";
import marloe from "../images/brands/marloe.png";
import quechua from "../images/brands/quechua.png";
import madeira from "../images/brands/madeira.png";
import merrell from "../images/brands/merrell.png";
import hyundai from "../images/brands/hyundai.png";
import huawei from "../images/brands/huawei.png";
import herschel from "../images/brands/herschel.png";
import happySocks from "../images/brands/happySocks.png";
import drbayard from "../images/brands/drbayard.png";
import samsung from "../images/brands/samsung.svg";

const brands = [
  madeira,
  samsung,
  azores,
  audi,
  timberland,
  sumol,
  quechua,
  merrell,
  hyundai,
  huawei,
  herschel,
  marloe,
  happySocks,
  drbayard,
].map((item) => <div className="Brand"><img src={item} ></img></div>);

export default function About() {
  const { t, i18n } = useTranslation();

  return (
    <div className="About">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | About</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <div className="Page-title">{t('About')}</div>
      <div className="About-content">
        <img className="Page-photo" src={joao} alt="João Falcão" />
        <div className="Page-content">
            <p>{t('About1')}</p>
            <p className="Bold">{t('AboutQuote')}</p>
          <div className="About-text">
            <a href="mailto:hello@joaofalcao.pt" className="Contacts-button">{t('Contacts')}</a>
            <p><span className="Bold">{t('Email')}: </span><a href="mailto:hello@joaofalcao.pt">hello@joaofalcao.pt</a></p>
            <p><span className="Bold">{t('Address')}: </span>Porto, Portugal</p>
            <p><span className="Bold">{t('Phone')}: </span>+351 93 746 22 68</p>
          </div>
          <div className="Brands">
            <p className="Bold">{t('Brands')}</p>
            <div className="Brands-grid">{brands}</div>
          </div>
        </div>
      </div>
    </div>
  );
}