import React, { useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import PageTitle from "../components/PageTitle";

import azores1 from "../images/commercial/azores/azores-1.jpg";
import azores4 from "../images/commercial/azores/azores-4.jpg";
import azores5 from "../images/commercial/azores/azores-5.jpg";
import azores7 from "../images/commercial/azores/azores-7.jpg";
import azores8 from "../images/commercial/azores/azores-8.jpg";
import azores9 from "../images/commercial/azores/azores-9.jpg";
import azores10 from "../images/commercial/azores/azores-10.jpg";
import azores12 from "../images/commercial/azores/azores-12.jpg";
import azores13 from "../images/commercial/azores/azores-13.jpg";
import azores14 from "../images/commercial/azores/azores-14.jpg";
import azores15 from "../images/commercial/azores/azores-15.jpg";
import azores16 from "../images/commercial/azores/azores-16.jpg";
import azores17 from "../images/commercial/azores/azores-17.jpg";
import azores18 from "../images/commercial/azores/azores-18.jpg";
import azores21 from "../images/commercial/azores/azores-21.jpg";
import azores22 from "../images/commercial/azores/azores-22.jpg";
import azores24 from "../images/commercial/azores/azores-24.jpg";
import azores25 from "../images/commercial/azores/azores-25.jpg";
import azores27 from "../images/commercial/azores/azores-27.jpg";
import azores28 from "../images/commercial/azores/azores-28.jpg";
import azores29 from "../images/commercial/azores/azores-29.jpg";

const photos = [
  {
    src: azores29,
    width: 4,
    height: 5,
  },
  {
    src: azores1,
    width: 3,
    height: 4,
  },
  {
    src: azores14,
    width: 3,
    height: 2,
  },
  {
    src: azores18,
    width: 4,
    height: 5,
  },
  {
    src: azores16,
    width: 3,
    height: 2,
  },
  {
    src: azores27,
    width: 3,
    height: 4,
  },
  {
    src: azores28,
    width: 5,
    height: 4,
  },
  {
    src: azores25,
    width: 3,
    height: 4,
  },
  {
    src: azores12,
    width: 4,
    height: 5,
  },
  {
    src: azores24,
    width: 4,
    height: 5,
  },
  {
    src: azores4,
    width: 3,
    height: 4,
  },
  {
    src: azores15,
    width: 3,
    height: 4,
  },
  {
    src: azores5,
    width: 3,
    height: 4,
  },
  {
    src: azores7,
    width: 4,
    height: 5,
  },
  {
    src: azores8,
    width: 4,
    height: 5,
  },
  {
    src: azores9,
    width: 4,
    height: 5,
  },
  {
    src: azores10,
    width: 4,
    height: 5,
  },
  {
    src: azores13,
    width: 3,
    height: 4,
  },
  {
    src: azores17,
    width: 4,
    height: 5,
  },
  {
    src: azores21,
    width: 2,
    height: 3,
  },
  {
    src: azores22,
    width: 4,
    height: 3,
  }
];

export default function Azores() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  function columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 3;
    if (containerWidth >= 1500) columns = 4;
    return columns;
  }

  return (
    <div className="Azores">
      <MetaTags>
        <title>João Falcão | @joaoffalcao | Visit Azores</title>
        <meta name="description" content="João Falcão Photography - @joaoffalcao | Portugal based Outdoor, Fashion, Wedding and Commercial Photographer || João Falcão Fotografia - @joaoffalcao | Fotógrafo de Natureza, Moda, Comercial e Casamentos do Porto." />
        <meta property="keywords" content="Fotografia, Fotógrafo, Photography, @joaoffalcao, Porto, Portugal, Outdoor, Fashion, Landscape, Portraits, Paisagem, Events, Brands, Content Creator, Casamentos, Fotógrafo de Casamentos, Batizados, Baptizados, Eventos, Commercial, Comercial, Natureza, Moda, Retratos, Marcas, Imobiliário" />
      </MetaTags>
      <PageTitle
        label="Visit Azores"
        img={azores28}
      ></PageTitle>
      <div className="Page-content">
        <div className="video-container">
          <iframe src="https://www.youtube.com/embed/m4AxjtSLalM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        margin={5}
        direction="column"
        columns={columns}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}